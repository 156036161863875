import React from "react";
import Bullet, { BulletSize, BulletVariant } from "../../../Bullet";
import { IconType } from "../../../Icon";
import Paragraph from "../../../Paragraph";
import { useTranslation } from 'gatsby-plugin-react-i18next';

// TODO: There is some weird spacing issues before going into tablet responsive view.
// It think we need to set relative width's on both absolute containers to fix it.
const PlatformFeatureBar: React.FC<{}> = () => {
  const {t} = useTranslation();
  return (
    <div className="relative flex justify-center w-full h-200 mt-18 lg:h-37 lg:mt-20">
      <div className="absolute grid grid-cols-1 grid-flow-row gap-36 top-40 lg:grid-rows-1 lg:grid-flow-col lg:gap-22 lg:top-9 lg:auto-cols-fr">
        <Dash />
        <Dash />
        <Dash />
      </div>
      <div className="absolute grid grid-cols-1 auto-cols-min grid-flow-row gap-22 lg:grid-rows-1 lg:grid-flow-col lg:gap-6 lg:auto-cols-fr">
        <PlatformFeatureOrb
          label={t("Marketplace Advertising Optimization")}
          iconType={IconType.optimize}
        />
        <PlatformFeatureOrb
          label={t("Display Advertising")}
          iconType={IconType.dsp}
        />
        <PlatformFeatureOrb
          label={t("Video Advertising")}
          iconType={IconType.video}
        />
        <PlatformFeatureOrb
          label={t("Market Intelligence Reporting")}
          iconType={IconType.lightbulb}
        />
      </div>
    </div>
  );
};

export default PlatformFeatureBar;

/**
 * Dashed line which is used between the orbs
 * @param props
 */
const Dash: React.FC<{}> = props => {
  return (
    <div className="flex  items-center justify-center border-l border-dashed border-blue-2-light h-16 w-0 lg:border-t lg:h-0 lg:w-44 lg:min-w-44">
      <div className="absolute h-2 w-2 bg-blue-2-light rounded-full" />
    </div>
  );
};

interface PlatformFeatureOrbProps {
  label: string;
  iconType: IconType;
  // svg: React.Component;
}
const PlatformFeatureOrb: React.FC<PlatformFeatureOrbProps> = props => {
  const { label, iconType } = props;
  return (
    <div
      className="flex flex-col items-center"
      style={{ width: "240px", minWidth: "240px" }}
    >
      <Bullet
        variant={BulletVariant.white}
        size={BulletSize.large}
        iconType={iconType}
      />
      <Paragraph className="mt-7 font-semibold">{label}</Paragraph>
    </div>
  );
};
