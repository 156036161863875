import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface RecommendationProps {
  text: string;
}

const Recommendation: React.FC<RecommendationProps> = props => {
  const {t} = useTranslation();
  const { text } = props;
  return (
    <div
      className="flex flex-row items-center justify-between bg-white rounded-xl shadow p-6"
      style={{
        width: "95%",
        maxWidth: "560px"
      }}
    >
      <div className="flex flex-col items-start">
        <p className="text-lg font-semibold mb-4" style={{ maxWidth: "246px" }}>
          {t(text)}
        </p>
        <div className="rounded-sm bg-gray-5-light w-60 h-3 mb-3" />
        <div className="rounded-sm bg-gray-5-light w-24 h-3" />
      </div>
      <div className="flex flex-row items-center space-x-3">
        <div className="rounded-full w-10 h-10 bg-gray-5-light border border-gray-4-light" />
        <div className="rounded-full w-10 h-10 bg-gray-5-light border border-gray-4-light" />
        <div className="rounded-full w-10 h-10 bg-indigo-3-light border border-indigo-2" />
      </div>
    </div>
  );
};

export default Recommendation;
