import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import SelectableButton from "../../SelectableButton";
import Testimonal from "../../Testimonial";
import MetricSelector, { MetricSelectorVariant } from "./MetricSelector";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AnalyzeSection: React.FC<{}> = () => {
  const [
    selectedMetricSelectorVariant,
    setSelectedMetricSelectorVarient
  ] = React.useState<MetricSelectorVariant>(MetricSelectorVariant.totalSales);

  const {t} = useTranslation();

  const headshot = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "headshots/ju-rhyu.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const testimonial = (
    <Testimonal
      quote={
        t("Perpetua gives us transparency and control. With Perpetua, we have full visibility into the impact of our ad spend, so we can swiftly double down on what's driving true incremental growth.")
      }
      name={"Ju Rhyu"}
      role={"CEO @ Hero Cosmetics"}
      image={headshot?.file?.childImageSharp?.fixed}
    />
  );

  return (
    <Section>
      <div className="flex flex-col-reverse lg:flex-row lg:py-36 py-20">
        <div className="block mt-8 md:mt-16 lg:hidden">{testimonial}</div>
        <div className="flex items-center justify-center lg:justify-start lg:w-1/2">
          <MetricSelector variant={selectedMetricSelectorVariant} />
        </div>
        <div className="text-center lg:text-left lg:w-1/2">
          <Header variant={HeaderVariant.h2}>
            {t("Analyze the")} <span className="text-transparent bg-clip-text bg-gradient-to-br from-purple-1 to-blue-1 animate-gradientRotate">{t("full")}</span>{" "}
            <span className="block">{t("picture")}</span>
          </Header>
          <Paragraph className="text-2xl font-semibold mb-4">
            {t("Measure the true impact of your advertising spend across multiple channels and marketplaces. Perpetua's customizable, enterprise-level reporting allows you to understand, at a single glance, how your business and products are performing in your category.")}
          </Paragraph>
          <div className="mb-11">
            <div className="flex flex-wrap items-center justify-center lg:justify-start">
              <SelectableButton
                text={t("Total Sales Analytics")}
                active={
                  selectedMetricSelectorVariant ===
                  MetricSelectorVariant.totalSales
                }
                onClick={() =>
                  setSelectedMetricSelectorVarient(
                    MetricSelectorVariant.totalSales
                  )
                }
                className="sm:mr-4 mt-4"
              />
              <SelectableButton
                text={t("Retail Intelligence")}
                active={
                  selectedMetricSelectorVariant ===
                  MetricSelectorVariant.retailIntelligence
                }
                onClick={() =>
                  setSelectedMetricSelectorVarient(
                    MetricSelectorVariant.retailIntelligence
                  )
                }
                className="sm:mr-4 mt-4"
              />
              <SelectableButton
                text={t("Digital Shelf Insights")}
                active={
                  selectedMetricSelectorVariant ===
                  MetricSelectorVariant.digitalShelfInsights
                }
                onClick={() =>
                  setSelectedMetricSelectorVarient(
                    MetricSelectorVariant.digitalShelfInsights
                  )
                }
                className="mt-4"
              />
            </div>
          </div>
          <div className="hidden lg:block">{testimonial}</div>
        </div>
      </div>
    </Section>
  );
};

export default AnalyzeSection;
