import React from "react";
import Section from "../../../containers/Section";
import { validateEmail } from "../../../utils/email";
import { nav } from "../../../utils/navigation";
import Brands, { BrandObject } from "../../Brands";
import Beekeepers from "../../Brands/BrandSvgs/beekeepers.svg";
import Crocs from "../../Brands/BrandSvgs/crocs.svg";
import FourSigmatic from "../../Brands/BrandSvgs/foursigmatic.svg";
import Franklin from "../../Brands/BrandSvgs/franklin.svg";
import HeroCosmetics from "../../Brands/BrandSvgs/hero-cosmetics.svg";
import Huggies from "../../Brands/BrandSvgs/huggies.svg";
import Kleenex from "../../Brands/BrandSvgs/kleenex.svg";
import Mikes from "../../Brands/BrandSvgs/mikes.svg";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import Header, { HeaderVariant } from "../../Header";
import Input from "../../Input";
import NavigationBarSpacer from "../../NavigationBarSpacer";
import Paragraph from "../../Paragraph";
import Orbit from "./Orbit";
import { CTA } from "../../../constants/text";
import { URL } from "../../../constants/destinations";
import {
  Name,
  Type,
  Location,
  FormType,
  ContentTitle
} from "../../../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const HeroSection: React.FC<{}> = props => {
  const [email, setEmail] = React.useState<string>("");

  const {t} = useTranslation();

  const brandsList: BrandObject[] = [
    { src: <Crocs />, filter: true },
    { src: <Kleenex />, filter: true },
    { src: <Franklin />, filter: true },
    { src: <Huggies />, filter: true },
    { src: <FourSigmatic />, filter: true },
    { src: <Beekeepers />, filter: true },
    { src: <HeroCosmetics />, filter: true },
    { src: <Mikes />, filter: true }
  ];

  return (
    <Section className="pb-22 pt-13 md:pb-24 lg:pt-16 lg:pb-36">
      <NavigationBarSpacer />
      <div className="flex flex-row mb-20">
        <div className="flex flex-col justify-center text-center md:text-left lg:w-1/2">
          <Header
            variant={HeaderVariant.h1}
            className="text-4xl md:text-7xl font-semibold md:font-bold"
          >
            <span className="text-transparent bg-clip-text md:block bg-gradient-to-r from-purple-1 to-blue-1 animate-gradientRotate">{t("Growth")}</span>{" "}
            {t("Infrastructure for eCommerce")}
          </Header>
          <Paragraph>
            {t("Join thousands of businesses that use Perpetua's eCommerce advertising optimization and intelligence software to profitably scale growth on Amazon, Instacart and other marketplaces.")}
          </Paragraph>
          <div className="flex items-center space-x-4 mt-10 mb-3">
            <Input
              placeholder={t("Enter your email")}
              className="w-full lg:w-85"
              value={email}
              onChange={e => setEmail(e?.target?.value)}
            />
            <Button
              id="home-header-getstarted"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              text={t("Get Started")}
              onClick={() => {
                if (validateEmail(email)) {
                  nav(`${URL.SignUp}/?email=${email}`);
                } else {
                    nav(URL.SignUp);
                }
              }}
            />
          </div>
          <div className="flex items-center justify-center md:justify-start">
            <p className="text-base font-medium text-gray-2-light">
              {t("Sign up with your email or request a demo")}
            </p>
          </div>
        </div>
        <div className="hidden w-1/2 lg:block ">
          <Orbit />
        </div>
      </div>
      <Brands brandsList={brandsList} />
    </Section>
  );
};

export default HeroSection;
