import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import useMedia from "use-media";
import Button, { ButtonVariant } from "../../../Button";
import DownArrow from "./DownArrow";
import UpArrow from "./UpArrow";
import { useTranslation } from 'gatsby-plugin-react-i18next';

export enum MetricSelectorVariant {
  totalSales = "total-sales",
  retailIntelligence = "retail-intelligence",
  digitalShelfInsights = "digital-shelf-insights"
}

interface MetricSelectorProps {
  variant: MetricSelectorVariant;
}

const MetricSelector: React.FC<MetricSelectorProps> = props => {
  const { variant } = props;

  const {t} = useTranslation();

  const images = useStaticQuery(graphql`
    query {
      digital: file(relativePath: { eq: "digital-shelf-insights.png" }) {
        childImageSharp {
          fluid(maxWidth: 628) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      retail: file(relativePath: { eq: "retail-intelligence.png" }) {
        childImageSharp {
          fluid(maxWidth: 628) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      total: file(relativePath: { eq: "total-sales.png" }) {
        childImageSharp {
          fluid(maxWidth: 628) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  // scale 0.95
  const scale95 = useMedia({ maxWidth: "1340px", minWidth: "1300px" });
  const scale90 = useMedia({ maxWidth: "1300px", minWidth: "1225px" });
  const scale75 = useMedia({ maxWidth: "1225px" });

  const variantImage = React.useMemo(() => {
    switch (variant) {
      case MetricSelectorVariant.digitalShelfInsights:
        return images?.digital?.childImageSharp?.fluid;
      case MetricSelectorVariant.retailIntelligence:
        return images?.retail?.childImageSharp?.fluid;
      case MetricSelectorVariant.totalSales:
        return images?.total?.childImageSharp?.fluid;
    }
  }, [variant]);

  if (scale75) {
    return (
      <div className="w-full">
        <Img fluid={variantImage} alt="metrics" />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "bg-white rounded-lg border border-gray-4-light shadow p-6 transform",
        {
          "scale-95": scale95,
          "scale-90": scale90
        }
      )}
      style={{ maxWidth: "600px" }}
    >
      <p className="text-sm font-semibold text-gray-2-light mb-4">
        {t("Edit Metrics")}
      </p>
      <div className="grid grid-cols-4 grid-rows-3 gap-4">
        {/* 2nd box - Retail intelligence */}
        <Metric
          {...(variant === MetricSelectorVariant.retailIntelligence
            ? {
                title: t("Organic Rank"),
                amount: "$160.3K",
                changeAmount: "16%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />

        {/* 2nd box - TOTAL SALES */}
        <Metric
          {...(variant === MetricSelectorVariant.totalSales
            ? {
                title: t("Blended ACoS"),
                amount: "16%",
                changeAmount: "22%",
                changeDirection: ChangeDirection.down
              }
            : {})}
          className={classNames({
            "z-20": variant === MetricSelectorVariant.totalSales
          })}
        />
        {/* 3rd box - Retail intelligence */}
        <Metric
          {...(variant === MetricSelectorVariant.retailIntelligence
            ? {
                title: t("Search Rank"),
                amount: "16%",
                changeAmount: "22%",
                changeDirection: ChangeDirection.down
              }
            : {})}
          {...(variant === MetricSelectorVariant.digitalShelfInsights
            ? {
                title: t("Search Frequency Rank"),
                amount: "1,957",
                changeAmount: "16%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />
        {/* 4nd box - TOTAL SALES */}
        <Metric
          {...(variant === MetricSelectorVariant.totalSales
            ? {
                title: t("Attributed Sales"),
                amount: "$160.3K",
                changeAmount: "16%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />
        {/* 5th box - TOTAL SALES */}
        <Metric
          {...(variant === MetricSelectorVariant.totalSales
            ? {
                title: t("Total Sales"),
                amount: "$185.5K",
                changeAmount: "46%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />
        {/* 6th box - Digital Shelf Insights*/}
        <Metric
          {...(variant === MetricSelectorVariant.digitalShelfInsights
            ? {
                title: t("Click Share"),
                amount: "22.32%",
                changeAmount: "22%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />
        <Metric />
        {/* 8th box - Retail Intelligence */}
        <Metric
          {...(variant === MetricSelectorVariant.retailIntelligence
            ? {
                title: t("Top Of Search SOV"),
                amount: "$185.5K",
                changeAmount: "46%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />
        <Metric />
        {/* 10th box - Retail Intelligence */}
        <Metric
          {...(variant === MetricSelectorVariant.retailIntelligence
            ? {
                title: t("Share of Voice"),
                amount: "16%",
                changeAmount: "22%",
                changeDirection: ChangeDirection.down
              }
            : {})}
        />
        {/* 11th box - TOTAL SALES */}
        <Metric
          {...(variant === MetricSelectorVariant.totalSales
            ? {
                title: t("Organic Sales"),
                amount: "$25.5K",
                changeAmount: "30%",
                changeDirection: ChangeDirection.up
              }
            : {})}
          {...(variant === MetricSelectorVariant.digitalShelfInsights
            ? {
                title: t("Conversion Share"),
                amount: "22.29%",
                changeAmount: "46%",
                changeDirection: ChangeDirection.up
              }
            : {})}
        />
        <Metric />
      </div>
      <div className="flex justify-end mt-6">
        <Button variant={ButtonVariant.primary} text={t("Done")} />
      </div>
    </div>
  );
};

export default MetricSelector;

enum ChangeDirection {
  up = "up",
  down = "down"
}

interface MetricProps {
  title?: string;
  amount?: string;
  changeAmount?: string;
  changeDirection?: ChangeDirection;
  className?: string;
}

const Metric: React.FC<MetricProps> = props => {
  const { title, amount, changeAmount, changeDirection, className } = props;

  const shouldShowContent = title && amount && changeAmount;
  return (
    <div
      className="relative"
      style={{
        minWidth: "121px",
        height: "80px",
        maxHeight: "80px"
      }}
    >
      <div
        className={classNames(
          "relative bg-white border border-gray-4-light rounded-lg min-w-min transition-transform ease-out duration-300",
          { "-top-2 -left-4 z-10 shadow transform scale-110": title },
          className
        )}
      >
        {shouldShowContent && (
          <div className="p-3" style={{ width: "155px" }}>
            <p className="text-sm font-semibold mb-2">{title}</p>
            <p className="text-2xl font-semibold mb-1">{amount}</p>
            <div className="flex items-center text-sm font-medium text-green-1">
              <div className="flex items-center mr-2">
                {changeDirection === ChangeDirection.up ? (
                  <UpArrow />
                ) : (
                  <DownArrow />
                )}
              </div>
              {changeAmount}
            </div>
          </div>
        )}
        {!shouldShowContent && (
          <div className="p-4" style={{ width: "121px" }}>
            <div className="rounded-sm bg-gray-5-light w-10 h-2 mb-3" />
            <div className="rounded-sm bg-gray-5-light w-16 h-3 mb-4" />
            <div className="rounded-sm bg-gray-5-light w-10 h-2" />
          </div>
        )}
      </div>
    </div>
  );
};
