import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import PlatformFeatureBar from "./PlatformFeatureBar";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const PlatformSection: React.FC<{}> = props => {
  const {t} = useTranslation();
  return (
    <div className="rounded-tr-3xl bg-gradient-to-b from-blue-3-light to-white bg-opacity-40">
      <Section>
        <div className="text-center pt-20 pb-20 md:pt-24 md:pb-24">
          <div className="m-auto w-full md:max-w-2xl">
            <Header variant={HeaderVariant.h2}>
              {t("A single platform to accelerate your growth")}
            </Header>
            <Paragraph className="text-2xl font-semibold">
              {t("Perpetua brings together everything needed to drive profitable market share growth for ecommerce brands.")}
            </Paragraph>
          </div>
          <PlatformFeatureBar />
        </div>
      </Section>
    </div>
  );
};

export default PlatformSection;
