import classNames from "classnames";
import React from "react";

interface SelectableButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The text to appear in the middle of the button
   */
  text?: string;
  /**
   * Force the button into an active (selected) state
   */
  active?: boolean;
}
const SelectableButton: React.FC<SelectableButtonProps> = props => {
  const { text, active, className, ...rest } = props;
  return (
    <button
      {...rest}
      className={classNames(
        "relative whitespace-nowrap flex flex-row items-center justify-center rounded-lg transition-colors px-6 py-3  border-2 focus:outline-none font-semibold",
        {
          [[
            "bg-white",
            "text-gray-2-light",
            "border-gray-4-light",
            "hover:text-gray-1",
            "hover:border-gray-1",
            "active:text-gray-2-dark",
            "active:bg-gray-6-light",
            "active:border-gray-2-dark"
          ].join(" ")]: !active,
          [[
            "bg-indigo-3-light",
            "text-indigo-1",
            "border-indigo-1",
            "hover:text-indigo-2",
            "hover:border-indigo-2",
            "active:bg-indigo-2-light",
            "active:text-indigo-3-dark",
            "active:border-indigo-3-dark"
          ].join(" ")]: active
        },
        className
      )}
    >
      {text}
    </button>
  );
};

export default SelectableButton;
