import Img, { FixedObject } from "gatsby-image";
import React from "react";

interface TestimonalProps {
  quote: string;
  image: FixedObject;
  name: string;
  role: string;
}
const Testimonal: React.FC<TestimonalProps> = props => {
  const { quote, image, name, role } = props;

  return (
    <div className="pl-8 border-l-4 border-gray-4-light">
      <blockquote className="text-lg font-medium mb-5">{quote}</blockquote>
      <div className="flex flex-row space-x-3">
        <Img className="rounded-lg" fixed={image} alt="testimonal headshot" />
        <div>
          <p className="text-lg font-semibold">{name}</p>
          <p className="text-base font-medium text-gray-1">{role}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonal;
