import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import loadable from '@loadable/component';

const Animation = loadable(() => import('./Animation/index'));

const Orbit: React.FC<{}> = () => {
  const orbitPlaceholder = useStaticQuery(graphql`
    query {
      orbit: file(relativePath: { eq: "index/marketplaces-illustration.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [loading, setLoading] = React.useState(true);

  useEffect(()=> {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? 
        <Img
          fluid={orbitPlaceholder?.orbit?.childImageSharp?.fluid}
          alt="Marketplaces perpetua connects with."
          style={{ width: "628px" }}
        />
        :
        <Animation />
      }
    </>
  );
};

export default Orbit;
