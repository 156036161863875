import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import Testimonal from "../../Testimonial";
import RecommendationSection from "./RecommendationSection";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AudienceSection: React.FC<{}> = () => {
  const {t} = useTranslation();

  const images = useStaticQuery(graphql`
    query {
      headshot: file(relativePath: { eq: "headshots/megan-loveland.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const testimonal = (
    <Testimonal
      quote={
        `"${t("Perpetua optimizes bids programmatically, which is faster and more accurate than our manual process. We now have time to focus on strategies, testing and analysis.")}"`
      }
      name={"Megan Loveland"}
      role={"Associate Manager, Etail and Distributors @ Crocs"}
      image={images?.headshot?.childImageSharp?.fixed}
    />
  );

  return (
    <div className="realtive bg-gray-6-light rounded-tl-3xl">
      <Section>
        <div className="flex flex-col lg:flex-row">
          <div className="pt-20 text-center lg:text-left lg:pb-20 lg:pt-20 lg:w-1/2">
            <Header variant={HeaderVariant.h2}>
              <span className="md:block flex">
              <span>{t("Reach audiences with")}</span>
              <span className="text-transparent bg-clip-text bg-gradient-to-br from-purple-1 to-blue-1 
              animate-gradientRotate">{t("superhuman")}</span>
              {t("precision")}
              </span>
            </Header>
            <Paragraph className="text-2xl font-semibold mb-11">
              {t("Perpetua's customizable, AI-powered smart recommendations and contextual, conversion-based bidding algorithms are designed to maximize the efficiency of your ad spend, ensure your campaigns never go dark and drive profitable market share growth.")}
            </Paragraph>
            <div className="hidden lg:block">{testimonal}</div>
          </div>
          <div className="relative overflow-y-hidden lg:w-1/2">
            <RecommendationSection />
          </div>
          <div className="block pb-20 md:pb-24 lg:hidden">{testimonal}</div>
        </div>
      </Section>
    </div>
  );
};

export default AudienceSection;
