import classNames from "classnames";
import React from "react";
import UpArrow from "../UpArrow";

interface DownArrowProps extends React.SVGAttributes<SVGElement> {}

const DownArrow: React.FC<DownArrowProps> = props => {
  const { className, ...rest } = props;
  return <UpArrow className={classNames("rotate-180", className)} {...rest} />;
};

export default DownArrow;
