import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Confetti from "react-confetti";
import Section from "../../../containers/Section";
import { useWindowSize } from "../../../hooks/useWindowSize";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import SelectableButton from "../../SelectableButton";
import Testimonal from "../../Testimonial";
import GoalConfigPanel, { GoalConfigPanelVariant } from "./GoalConfigPanel";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const GoalSection: React.FC<{}> = () => {
  const [
    selectedGoalConfigPanelVariant,
    setSelectedGoalConfigPanelVarient
  ] = React.useState<GoalConfigPanelVariant>(GoalConfigPanelVariant.brand);

  const [showConfetti, setShowConfetti] = React.useState(false);

  const {t} = useTranslation();

  const launchGoal = () => {
    setTimeout(() => {
      setShowConfetti(true);
    }, 500);
  };

  const size = useWindowSize();

  const goalSectionMedia = useStaticQuery(graphql`
    query {
      headshot: file(relativePath: { eq: "headshots/daniel-zakowski.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      productImage: file(relativePath: { eq: "index/product-list.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const testimonal = (
    <Testimonal
      quote={
        `“${t("With Perpetua, we can easily map and measure the incrementality of our advertising performance. Perpetua makes us smarter and helps us optimize our ads to improve our bottom line.")}”`
      }
      name="Daniel Zakowski"
      role="CEO @ Ready, Set, Food!"
      image={goalSectionMedia?.headshot?.childImageSharp?.fixed}
    />
  );

  return (
    <Section>
      <div className="pt-20 lg:pt-32 pb-20 lg:pb-40 flex flex-col-reverse lg:flex-row">
        <div className="block mt-8 md:mt-20 lg:hidden">{testimonal}</div>
        <div className="relative flex items-center justify-start lg:w-1/2">
          <Img
            fluid={goalSectionMedia?.productImage?.childImageSharp?.fluid}
            alt="product list"
            className="w-72 md:w-96 z-0 my-6 lg:my-0"
          />
          <div className="absolute -right-10 md:right-3 lg:right-16 transform scale-75 md:scale-100">
            <GoalConfigPanel
              variant={selectedGoalConfigPanelVariant}
              onClick={launchGoal}
            />
          </div>
          {showConfetti && (
            <div className="fixed top-0 left-0 w-full h-screen">
              <Confetti
                colors={[
                  "#8241F3",
                  "#42A5F5",
                  "#D2BBFB",
                  "#F5F0FE",
                  "#BBDFFB",
                  "#F0F8FE"
                ]}
                style={{ position: "fixed" }}
                width={size.width}
                height={size.height}
                run={showConfetti}
                recycle={false}
                numberOfPieces={1000}
                onConfettiComplete={() => setShowConfetti(false)}
              />
            </div>
          )}
        </div>
        <div className="text-center lg:text-left lg:w-1/2">
          <Header variant={HeaderVariant.h2}>
            {t("Create")} <span className="text-transparent bg-clip-text bg-gradient-to-br from-purple-1 to-blue-1 animate-gradientRotate">{t("Goals")}</span>,{" "}
            <span className="md:block">{t("Not Campaigns")}</span>
          </Header>
          <Paragraph className="text-2xl font-semibold">
            {t("Save time and money with automated campaign creation, execution and optimization. Input your strategic objectives (growth, profitability, brand defense, awareness), and rely on Perpetua's ad engine to execute tactically.")}
          </Paragraph>
          <div className="flex flex-wrap items-center justify-center space-x-4 mt-4 mb-8 md:mb-20 lg:mb-11 lg:justify-start">
            <SelectableButton
              className="mt-4"
              text={t("Universal")}
              active={
                selectedGoalConfigPanelVariant ===
                GoalConfigPanelVariant.universal
              }
              onClick={() =>
                setSelectedGoalConfigPanelVarient(
                  GoalConfigPanelVariant.universal
                )
              }
            />
            <SelectableButton
              className="mt-4"
              text={t("Brand Based")}
              active={
                selectedGoalConfigPanelVariant === GoalConfigPanelVariant.brand
              }
              onClick={() =>
                setSelectedGoalConfigPanelVarient(GoalConfigPanelVariant.brand)
              }
            />
            <SelectableButton
              className="mt-4"
              text={t("Advanced")}
              active={
                selectedGoalConfigPanelVariant ===
                GoalConfigPanelVariant.advanced
              }
              onClick={() =>
                setSelectedGoalConfigPanelVarient(
                  GoalConfigPanelVariant.advanced
                )
              }
            />
          </div>
          <div className="hidden lg:block">{testimonal}</div>
        </div>
      </div>
    </Section>
  );
};

export default GoalSection;
