import React from "react";

interface UpArrowProps extends React.SVGAttributes<SVGElement> {}

const UpArrow: React.FC<UpArrowProps> = props => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.41608 3.62402C5.21817 3.32715 4.78194 3.32715 4.58403 3.62402L1.51829 8.22263C1.29677 8.55491 1.53496 8.99998 1.93431 8.99998L4.00005 8.99998V12.5C4.00005 12.7761 4.22391 13 4.50005 13H5.50005C5.7762 13 6.00005 12.7761 6.00005 12.5V8.99998L8.0658 8.99998C8.46514 8.99998 8.70334 8.55491 8.48182 8.22263L5.41608 3.62402Z"
        fill="#00C853"
      />
    </svg>
  );
};

export default UpArrow;
