import React from "react";
import { graphql } from "gatsby";
import BottomCTA from "../components/BottomCta";
import AnalyzeSection from "../components/IndexPage/AnalyzeSection";
import AudienceSection from "../components/IndexPage/AudienceSection";
import GoalSection from "../components/IndexPage/GoalSection";
import HeroSection from "../components/IndexPage/HeroSection";
import PlatformSection from "../components/IndexPage/PlatformSection";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { nav } from "../utils/navigation";
import { URL } from "../constants/destinations";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const IndexPage: React.FC<{}> = () => {

  const {t} = useTranslation();

  return (
  <Layout
    primaryButtonId="home-nav-getstarted"
    secondaryButtonId="home-nav-signin"
  >
    <SEO
      title="eCommerce Advertising Software"
      description="Perpetua provides eCommerce advertising software and managed services for marketplaces including Amazon and Instacart — plus solutions for Amazon DSP, OTT and Google ads."
      image={`https://perpetua.io/preview-image.png`}
    />
    <HeroSection />
    <PlatformSection />
    <GoalSection />
    <AudienceSection />
    <AnalyzeSection />
    <BottomCTA
      primaryButtonId="home-bottom-getstarted"
      secondaryButtonId="home-bottom-demo"
      title={t("Getting started is easy")}
      subtitle={
        t("Everything you need to grow your ecommerce business, in one platform")
      }
      primaryButtonText={t("Get Started")}
      secondaryButtonText={t("Request a Demo")}
      primaryButtonOnClick={() => {
        nav(URL.Login);
      }}
    />
  </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;