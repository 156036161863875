import React from "react";
import Button, { ButtonSize, ButtonVariant } from "../../../Button";
import { useTranslation } from 'gatsby-plugin-react-i18next';

export enum GoalConfigPanelVariant {
  universal = "universal",
  brand = "brand-based",
  advanced = "advanced"
}

interface GoalConfigPanelProps {
  variant: GoalConfigPanelVariant;
  onClick: () => void;
}
const GoalConfigPanel: React.FC<GoalConfigPanelProps> = props => {
  const { variant, onClick } = props;

  const {t} = useTranslation();

  return (
    <>
      <div
        className="bg-white shadow rounded-2xl border border-gray-4-light p-6 space-y-6 transition-all"
        style={{
          minWidth: "344px"
        }}
      >
        {variant === GoalConfigPanelVariant.universal && (
          <>
            <SegmentInput segmentName="Universal" />
          </>
        )}
        {variant === GoalConfigPanelVariant.brand && (
          <>
            <SegmentInput segmentName="Branded" />
            <SegmentInput segmentName="Unbranded" />
          </>
        )}
        {variant === GoalConfigPanelVariant.advanced && (
          <>
            <SegmentInput segmentName="Branded" />
            <SegmentInput segmentName="Category" />
            <SegmentInput segmentName="Competitor" />
          </>
        )}
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          text={t("Launch Goal")}
          onClick={onClick}
          className="mt-8 w-full"
        />
      </div>
    </>
  );
};

export default GoalConfigPanel;

interface SegmentInputProps {
  segmentName: string;
}

const SegmentInput: React.FC<SegmentInputProps> = props => {
  const { segmentName } = props;
  
  const {t} = useTranslation();

  let budget = 200;
  let acos = 12;
  switch (segmentName) {
    case "Universal":
      budget = 300;
      acos = 15;
      break;
    case "Branded":
      budget = 190;
      acos = 7;
      break;
    case "Unbranded":
      budget = 200;
      acos = 20;
      break;
    case "Category":
      budget = 450;
      acos = 30;
      break;
    case "Competitor":
      budget = 300;
      acos = 40;
      break;
  }
  return (
    <div>
      <p className="text-lg font-semibold mb-3">{t(segmentName)}</p>
      <div className="flex flex-row items-center justify-between">
        <span>
          <p className="text-base font-semibold text-gray-2-light mb-2">
            {t("Target ACoS")}
          </p>
          <div className="w-35 border border-gray-3-light rounded-lg py-2 px-3">
            {acos}%
          </div>
        </span>
        <span>
          <p className="text-base font-semibold text-gray-2-light mb-2">
            {t("Daily Budget")}
          </p>
          <div className="w-35 border border-gray-3-light rounded-lg py-2 px-3">
            ${budget}
          </div>
        </span>
      </div>
    </div>
  );
};
