import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { animated, config, useSprings } from "react-spring";
import useMedia from "use-media";
import Recommendation from "../Recommendation";

const recommendations: { text: string; }[] = [
  {
    text: "New keyword opportunity! Boost fancy shoes",
  },
  {
    text: "Low inventory, decrease ACoS to 35%"
  },
  {
    text: "Improve organic rank for the keyword comfy socks"
  },
  {
    text: "Low inventory, decrease ACoS to 35%"
  },
  // DUPLICATES
  {
    text: "New keyword opportunity! Boost fancy shoes",
  },
  {
    text: "Low inventory, decrease ACoS to 35%"
  },
  {
    text: "Improve organic rank for the keyword comfy socks"
  },
  {
    text: "Low inventory, decrease ACoS to 35%"
  },
];

const RecommendtionSection: React.FC<{}> = () => {
  const images = useStaticQuery(graphql`
    query {
      recommendations: file(relativePath: { eq: "index/recommendation.png" }) {
        childImageSharp {
          fluid(maxWidth: 628) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [recommendationSprings, set] = useSprings(
    recommendations.length,
    index => {
      return {
        from: { transform: `translate(${index % 2 === 0 ? 0 : -16}px, 0px)` },
        to: async next => {
          let count = 1;
          while (count <= 7) {
            await next({
              transform: `translate(${
                index % 2 === 0
                  ? count % 2 === 0
                    ? -16
                    : 0
                  : count % 2 === 0
                  ? 0
                  : -16
              }px, ${count * -137}px)`
            });
            count = count + 1;
          }
        },
        loop: true,
        config: {
          ...config.gentle,
          duration: 6000
        }
      };
    }
  );
  const isDesktop = useMedia({ minWidth: "1024px" });

  const desktopRecommendations = (
    <div
      className="flex absolute w-full flex-col items-center space-y-3 overflow-y-hidden right-2 lg:items-end"
      style={{
        maxHeight: "774px"
      }}
    >
      {recommendationSprings.map((props, idx) => {
        const recommendation = recommendations[idx];
        return (
          <animated.div
            key={idx}
            className="w-full flex justify-end"
            style={props}
          >
            <Recommendation {...recommendation} />
          </animated.div>
        );
      })}
    </div>
  );

  return (
    <div className="relative w-full h-full overflow-hidden">
      {!isDesktop && (
        <div className="pb-8 md:pb-6 lg:pb-0">
        <Img
          fluid={images?.recommendations?.childImageSharp?.fluid}
          alt={"recommendations"}
        />
        </div>
      )}
      {isDesktop && desktopRecommendations}
      <div
        className="absolute top-0 w-full h-14 md:h-32 lg:h-48"
        style={{
          background:
            "linear-gradient(180deg, #F8FAFC 0%, rgba(248, 250, 252, 0) 100%)"
        }}
      />
      <div
        className="absolute bottom-0 w-full h-14 md:h-32 lg:h-48"
        style={{
          background:
            "linear-gradient(180deg, rgba(248, 250, 252, 0) 0%, #F8FAFC 100%)"
        }}
      />
    </div>
  );
};

export default RecommendtionSection;
